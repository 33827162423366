.changeTheme {
  position: fixed;
  right: 10px;
  bottom: 100px;
  height: 30px;
  font-size: 3rem;
  z-index: 999;
}

.changeTheme button {
  outline: none;
  border: none;
  cursor: pointer;
  background: none;
  padding: 20px;
}

@media screen and (max-width: 800px) {
  .changeTheme {
    right: -10px;
    bottom: 90px;
    font-size: 2.75rem;
  }
}

@media screen and (max-width: 600px) {
  .changeTheme {
    right: -5px;
  }
}